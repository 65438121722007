import React from 'react'

const AboutCard = () => {
    return (
        <>
            <div className="row">
                <div className="box about-box">
                    <div className="box-body">
                        <div className="box-number">01</div>
                        <h4>PRISM LOCKER</h4>
                        <p>At the core of our project lies our powerful liquidity locking platform, which ensures that your funds are always protected and readily available when you need them. By leveraging the Blast Network's advanced capabilities, we are able to provide a seamless and efficient service, making it easier than ever to manage your digital assets with confidence.
</p>
                    </div>
                </div>
                <div className="box about-box"><div className="box-body">
                    <div className="box-number">02</div>
                    <h4>PRISM DEX</h4>
                    <p>The prism DEX has Machine learning and AI powered Algorithmic trading tools integrated.
Experience unparalleled transaction speed, ensuring your trades are executed swiftly and efficiently, leaving lag behind.
Dive into our exclusive Prism Pools, offering a curated selection of tokens and assets. These pools are not just about liquidity; they're designed for optimal trading experiences.</p>
                </div></div>
                <div className="box about-box">
                    <div className="box-body">
                        <div className="box-number">03</div>
                        <h4>PRISMPAD</h4>
                        <p>Prismpad on the Blast Network ensures your projects lift off at unmatched speeds. Say goodbye to delays and welcome a seamless launch experience.
Unlike traditional launchpads, Prismpad empowers the community to have a say in project selection. Decentralized decision-making ensures a diverse range of projects, all curated by the people.
</p>
                    </div>
                </div>

                
            </div>
        </>
    )
}

export default AboutCard
