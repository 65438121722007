import React, { useState } from "react";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <header>
        <nav className="navbar">
          <a href="/" className="logo">
            <img src="./blank.png" className="logo-img" alt="" />
            <h2>
              PRISM <span>NETWORK</span>
            </h2>
          </a>

          <div className={openMenu ? "menu active" : "menu"}>
            <ul className="navbar-list">
              <li>
                <a
                  href="#about-us"
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="https://prism-network-1.gitbook.io/the-prism-network-whitepaper/"
                  target="_blank"
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                >
                  Docs
                </a>
              </li>



              {/* <li>
                <a
                  href="#roadmap"
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                >
                  Roadmap
                </a>
              </li> */}

              
              <li>
                <a
                  href="#"
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Dapp
                </a>


              </li>

              <li>
                <a
                  href="#tokenomics"
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                >
                  Features
                </a>
              </li>
            </ul>

            <div className="mobile-navbar-btn d-flex align-items-center">
              <div
                className="openNav-btn menu-btn"
                onClick={() => {
                  setOpenMenu(true);
                }}
              >
                <i className="fa-solid fa-bars"></i>
              </div>
              <div
                className="closeNav-btn menu-btn"
                onClick={() => {
                  setOpenMenu(false);
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
