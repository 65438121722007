import React from 'react'

const TokenCard = () => {
    return (
        <>
            <div className="row">
                <div className="box token-box">
                    <div className="box-body">
                        <h4><b>Liquidity Brilliance:</b> Prism Network introduces a state-of-the-art Liquidity Locking Platform, ensuring your assets are not just secure but thriving in a fortress of financial brilliance.</h4>
                    </div>
                </div>
                <div className="box token-box">
                    <div className="box-body">
                        <h4><b>Blast Network Integration: </b>  Powered by the innovative Blast Network, our platform takes decentralization to new heights, providing a robust and secure environment for your crypto endeavors.
</h4>
                    </div>
                </div>
                <div className="box token-box">
                    <div className="box-body">
                        <h4><b>User Friendliness:
</b> Our dApps feature a user-friendly interface that is seamlessly integrated for convenient usage across various devices, including desktop computers and mobile phones.
</h4>
                    </div>
                </div>
                <div className="box token-box">
                    <div className="box-body">
                        <h4><b>Low Gas And Fees:
</b> The platform operates on the blast networks, boasting significantly reduced gas fees compared to Layer 1 solutions, while ensuring near-instant transaction processing times. In other words, users can enjoy faster and more cost-efficient transactions on this platform compared to other blockchain networks.
</h4>
                    </div>
                </div>
            </div>

            {/* <div className="box-body"> <h5>Prism Network on the Blast Chain prioritizes security at its core. Our Liquidity Locking Platform, DEX, and Prism Launchpad all adhere to advanced encryption and multi-layered protection, ensuring your assets are safeguarded against any potential threat. Your journey with Prism Network is not just about utility; it's about forging a secure path to financial brilliance. Whether you are securing your liquidity, trading with precision on our DEX, or participating in groundbreaking launches with Prismpad, Prism Network is your gateway to a diversified and secure crypto portfolio.</h5></div> */}
        </>
    )
}

export default TokenCard
