import React from 'react'
import Header from './components/Header'

const Home = () => {
    return (
        <>
            <Header />
            <div className="container">
                <div className="home-section">
                    <div className="home-data">
                        <h1 className='topData'>Embark on a revolutionary experience with Prism Network</h1>
                        <p className='paraData'>where transparency meets clarity,diversity and security on the cutting-edge Blast Network!
</p>
                        <a href='#' target="_blank"><button className='btn'>LAUNCH APP(Coming soon) <span><i className="fa-solid fa-arrow-right-long"></i></span></button></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
